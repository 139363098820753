<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import { tableData } from "../../tables/data-advancedtable";
import { authUserMethods } from "../../../../state/helpers/authuser"
import DatePicker from "vue2-datepicker";
import moment from 'moment';
import {
  required,
} from "vuelidate/lib/validators";
/**
 * Blank page component
 */
export default {
  page: {
    title: "Staff",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, DatePicker },
  data() {
    return {
      tableData: tableData,
      is_sort_asc: true,
      is_sort_ascdesc_enable: false,
      active_column: '',
      user: {
        first_name: '',
        last_name: '', 
        email: '',
        password: '',
        phone: '',
        start_date: '',
        title: '',
      },
      staffs: [],
      tab_items: [
        { id: 1, title: "Infomation" },
      ],
      title: "Staff",
      user_access: {},
      is_tab_active: 1,
      is_update: false,
      submitted: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      page:1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      can_login: false,
      fields: [
        { key: "first_name", label:"Name"},
        { key: "email" },
        { key: "phone" },
        { key: "title" },
        {key: 'user_access.can_login', label: "Login"}
        // {key: 'is_manager', label: "Role"}
      ]
    };
  },
  validations: {
    user: {
      first_name: {required},
      last_name: {required},
      phone: {required},
      email: {required},
      title: {required},
      start_date: {required},
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.getAllStaff()
    // Set the initial number of items
    // this.totalRows = this.items?.length;
  },
  methods: {
    ...authUserMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllStaff() {
      return this.allStaffs({ paginate: true, page: this.page,  order_field: 'first_name', ranking: 'asc'.toUpperCase() }).then((response) => {
        // eslint-disable-next-line no-console
        this.staffs = response.data.data
        this.totalRows = response.data.total
      })
    },
    handlePageChange(page){
      const field = this.active_column
      let query;
      if(field != '') {
        query =  { paginate: true, is_manager: false, page, order_field: field, ranking: this.is_sort_asc ? 'asc'.toUpperCase() : 'desc'.toUpperCase() }
      }else{
        query = { paginate: true, page, order_field: 'first_name', ranking: 'asc'.toUpperCase() }
      }


      return this.allStaffs(query).then((response) => {
        // eslint-disable-next-line no-console
        this.page = page
        this.staffs = response.data.data
        this.totalRows = response.data.total
      })
    },
    searchData(event) {
      // eslint-disable-next-line no-console
      const value = event.target.value
      return this.allStaffs({ paginate: true, keyword: value, order_field: 'first_name', ranking: 'asc'.toUpperCase() }).then((response) => {
        // eslint-disable-next-line no-console
        this.staffs = response.data.data
        this.totalRows = response.data.total
      })
    },
    getUserAccess(id) {
      return this.showUserAccess(id).then((response) => {
        // eslint-disable-next-line no-console
        this.user_access = response.data
        this.can_login = response.data.can_login
      })
    },
    showModal() {
      this.$bvModal.show('user-modal')
    },
    hide() {
      this.getAllStaff()
      this.$bvModal.hide('user-modal')
      this.is_update = false;
      const tab_view = this.tab_items.filter(item => item.id !== 2);
      this.tab_items = tab_view;
      this.is_tab_active = 1;
      this.emptyData()
    },
    showFormContent(id) {
      this.is_tab_active = id;
    },
    showDetails(data) {
      this.getUserAccess(data.account);
      this.user = data
      if(this.user.start_date === null){
        this.user.start_date = ""
      }else {
        this.user.start_date = moment(data.start_date).toDate();
      }
      this.is_update = true;
      const tab_view = this.tab_items.filter(item => item.id === 2);
      if(tab_view.length < 1) {
        this.tab_items.push({ id: 2, title: "Status" })
      }
      this.showModal()
    },
    saveProfile() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        
        this.registerStaff(this.user).then(() => {
          this.hide()
        })
      }
      this.submitted = false;
    },
    updateProfile() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if(this.user.start_date){
        this.user.start_date = moment(this.user.start_date).format('YYYY-MM-DD');
      }
      delete this.user.visible_on_website_position
        this.updateStaff(this.user).then(() => {
          this.hide()
        })
      }
      this.submitted = false;
    },
    emptyData() {
      this.user =  {
        first_name: '',
        last_name: '', 
        email: '',
        phone: '',
        password: '',
        start_date: '',
        title: '',
      }
    },
    toggleSwitch() {
      // eslint-disable-next-line no-console
      const data = this.user_access;
      data.can_login = this.can_login
      this.toggleUserAccess(data).then(() => {
        this.getAllStaff()
      })
    },
    toggleMarkAsMangerSwitch() {
      const data = this.user;
      delete data.start_date;
      delete data.visible_on_website_position;
      // eslint-disable-next-line no-console
      if(this.user.start_date){
        this.user.start_date = moment(this.user.start_date).format('YYYY-MM-DD');
      }
      this.updateStaff(data).then(() => {
          this.getAllStaff()
        })
    },
    async handleTableHeadClicked(item){
      this.active_column = item
      this.is_sort_asc = !this.is_sort_asc
      let query = { paginate: true, page: this.page, order_field: item, ranking: this.is_sort_asc ? 'asc'.toUpperCase() : 'desc'.toUpperCase() }
      await this.allStaffs(query).then((response) => {
        // eslint-disable-next-line no-console
        this.staffs = response.data.data
        this.totalRows = response.data.total
      })
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
        <div class="col-md-6 col-lg-6">
          <b-card no-body class="mt-5">

      
<b-card-body>
  <b-card-title class="d-flex mb-5 align-items-center justify-content-between">
    <h1 class="card-title">Staff List</h1>
    <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="showModal">Add New</a>
  </b-card-title>

  <b-card-title class="d-flex mb-5 align-items-center justify-content-between">
    <div class="row" style="flex: 1">
      <div class="col-md-5">
        <label class="form-label" for="validationCustom04">Search for Staff</label>
        <input id="validationCustom04"  type="text" class="form-control" @input="searchData"/>
      </div>
    </div>
</b-card-title>
  <b-card-text>
      <!-- Table -->
      <div class="table-responsive mb-0 datatables account_table">
        <b-table
          :items="staffs"
          :fields="fields"
          responsive="sm"
          @row-clicked="showDetails"
          id="app-table" 
          borderless="borderless" outlined="outlined" fixed="fixed" sticky-header="true"
          striped
          @head-clicked="handleTableHeadClicked"
        >
        <template #head()="data">
                        <div class="table_heading_view">
                          <span class="">{{ data.label}}</span>
                          <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip"
                            v-if="is_sort_asc && data.column === active_column"></i>
                          <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip"
                            v-if="!is_sort_asc && data.column === active_column"></i>
                        </div>

                      </template>
        <template #cell(first_name)="data">
  {{ data.item.first_name }} {{ data.item.last_name }}
</template>
<template #cell(user_access.can_login)="data">
        <span class="mdi mdi-check-bold text-success" v-if="data.value == 1"></span>
        <span v-else></span>
      </template>
      </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="handlePageChange"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
  </b-card-text>



  <!-- user modal -->
  <b-modal id="user-modal" :title="is_update ? 'Update Staff Account': 'Create Staff Account'" title-class="font-18" size="lg" hide-footer @hide="hide">
<div class="bp_form_box">
  <div class="bp_form_side_bar">
    <div v-for="(data, index) in tab_items" :key="index" class="bp_tab_link"
      :class="is_tab_active === data.id ? 'bp_active' : ''" @click="showFormContent(data.id)">
      <div class="bp_tab_counter">
        {{ index + 1 }}
      </div>
      <div class="bp_text_view">
        <span> {{ data.title }} </span>
      </div>
    </div>
  </div>

  <div class="bp_form_view">
    <div class="form_content">
      <div class="row" v-if="is_tab_active === 1">

        <div class="col-md-12">
          <!-- <h4 class="mb-3 mt-3">Infomation</h4> -->
          <form class="needs-validation">
          <div class="row">
            
            <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">First Name</label>
              <input id="validationCustom04" v-model="user.first_name" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.first_name.$error,
                }" />
              <div v-if="submitted && $v.user.first_name.$error" class="invalid-feedback">
                <span v-if="!$v.user.first_name.required">This value is required.</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Last Name</label>
              <input id="validationCustom04" v-model="user.last_name" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.last_name.$error,
                }" />
              <div v-if="submitted && $v.user.last_name.$error" class="invalid-feedback">
                <span v-if="!$v.user.last_name.required">This value is required.</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Phone</label>
              <input id="validationCustom04" v-model="user.phone" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.phone.$error,
                }" />
              <div v-if="submitted && $v.user.phone.$error" class="invalid-feedback">
                <span v-if="!$v.user.phone.required">This value is required.</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Email</label>
              <input id="validationCustom04" v-model="user.email" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.email.$error,
                }" />
              <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                <span v-if="!$v.user.email.required">This value is required.</span>
              </div>
            </div>
          </div>

                 
          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Title</label>
              <input id="validationCustom04" v-model="user.title" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.title.$error,
                }" />
              <div v-if="submitted && $v.user.title.$error" class="invalid-feedback">
                <span v-if="!$v.user.title.required">This value is required.</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Start Date</label>
            
              <date-picker
                    id="validationCustom04"
                    v-model="user.start_date"
                    format="DD MMMM, YYYY"
                    lang="en"
                    :class="{
                        'is-invalid': submitted && $v.user.start_date.$error,
                      }" 
                  ></date-picker>
                  <div v-if="submitted && $v.user.start_date.$error" class="invalid-feedback">
                      <span v-if="!$v.user.start_date.required">This value is required.</span>
                    </div>
            </div>
          </div>


   
          <!-- <div class="col-md-12">
            <hr />
            <h5>Address</h5>
            <hr />
          </div> -->

          <!-- <div class="col-md-12">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Address</label>
              <input id="validationCustom04" v-model="user.address" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.address.$error,
                }" />
              <div v-if="submitted && $v.user.address.$error" class="invalid-feedback">
                <span v-if="!$v.user.address.required">This value is required.</span>
              </div>
            </div>
          </div> -->

<!-- 
          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">City</label>
              <input id="validationCustom04" v-model="user.city" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.city.$error,
                }" />
              <div v-if="submitted && $v.user.city.$error" class="invalid-feedback">
                <span v-if="!$v.user.city.required">This value is required.</span>
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-3">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">State</label>
              <input id="validationCustom04" v-model="user.state" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.state.$error,
                }" />
              <div v-if="submitted && $v.user.state.$error" class="invalid-feedback">
                <span v-if="!$v.user.state.required">This value is required.</span>
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-3">
            <div class="mb-4">
              <label class="form-label" for="validationCustom04">Zip Code</label>
              <input id="validationCustom04" v-model="user.zip_code" type="text" class="form-control"
                placeholder="" :class="{
                  'is-invalid': submitted && $v.user.zip_code.$error,
                }" />
              <div v-if="submitted && $v.user.zip_code.$error" class="invalid-feedback">
                <span v-if="!$v.user.zip_code.required">This value is required.</span>
              </div>
            </div>
          </div> -->
          
          </div>

        </form>
        </div>
      </div>

      <div class="row" v-if="is_tab_active === 2">

        <!-- <div class="col-md-12">
          <div class="check_box_view mb-2">
            <h5>Mark As {{user.is_manager ? 'Staff' : 'Manager'}}</h5>
            <div class="form-check form-switch">
<input class="form-check-input" v-model="user.is_manager" type="checkbox" role="switch" id="flexSwitchCheckDefault" @change="toggleMarkAsMangerSwitch">
</div>
          </div>
        </div> -->

<!-- 
        <div class="col-md-12">
          <hr/>
          </div> -->
        <div class="col-md-12">
          <div class="check_box_view">
            <h5>Login Enabled</h5>
            <div class="form-check form-switch">
<input class="form-check-input" v-model="can_login" type="checkbox" role="switch" id="flexSwitchCheckDefault" @change="toggleSwitch">
</div>
          </div>
        </div>
      </div>
    </div>
    <div class="button_view d-flex align-items-center justify-content-between" v-if="is_tab_active === 1">
      <button type="button" class="btn btn-outline-secondary" @click="hide">Cancel</button>
      <button type="button" class="btn btn-outline-success" @click="is_update ? updateProfile() : saveProfile()">Save</button>
    </div>

    <!-- <div class="button_view d-flex align-items-center justify-content-between" v-if="is_tab_active === 2">
      <button type="button" class="btn btn-outline-secondary" @click="hide">Cancel</button>
      <button type="button" class="btn btn-outline-success" @click="saveProfilePassword">Save</button>
    </div> -->

  </div>
</div>
</b-modal>

  </b-card-body>
</b-card>
        </div>
      </div>

  </Layout>
</template>